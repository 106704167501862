<template>
  <div>
    <section id="page-title" class="page-title-parallax page-title-center page-title-dark include-header" style="background-image: linear-gradient(to top, rgba(254,150,3,0.5), #39384D), url('/assets/images/services/banner/sales.jpg'); background-size: cover; padding: 120px 0;" data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">
        <div id="particles-line"></div>

        <div class="container clearfix mt-4">
            <div class="badge badge-pill border border-light text-light">Sales Success</div>
            <h1>Sales Success</h1>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Sales Success</li>
            </ol>
        </div>

    </section>
    <section id="content">

			<div class="content-wrap pb-0">
                <!-- Main Content
				============================================= -->
				<div class="container">
					<div class="row align-items-end">
						<div class="col-lg-6">
							<div class="badge badge-pill badge-default">Sales Success</div>
							<h2 class="display-4 font-weight-bold">EMPOWERING SALES ASSOCIATES.</h2>
							<p>
                                With eFlex, you can be rest assured that our sales associates have a strong understanding of the sales process and excel in building relationships with your partners. They stay committed to helping you closing deals and making sales.
                            </p>
							<router-link to="/contact"><a class="button button-rounded button-large nott ls0">Get Started</a></router-link>
						</div>
						<div class="col-lg-6 mt-4 mt-lg-0">
							<img src="/assets/images/services/header/sales.jpg" alt="Image">
						</div>
					</div>
				</div>
				<!-- Features
				============================================= -->
				<div class="section mb-0">
					<div class="container">
						<div class="heading-block border-bottom-0 center">
							<div class="badge badge-pill badge-default">Services</div>
							<h3 class="nott ls0">What We Can Do</h3>
                            <p>Our sales agents represent your company. We help maximize your sales performance and provide assistance to your customers throughout the sales process. With a demonstrated knowledge of your products and services, eFlex’s sales representatives can help in effectively educating prospects on your product or service.</p>
						</div>
						<div class="row col-mb-30 align-content-stretch">

							<div class="col-lg-6 col-md-6 d-flex">
								<div class="card">
									<div class="card-body p-5">
										<div class="feature-box flex-column">
											<div class="mb-3">
												<img src="/assets/images/services/pagecards/sales1.jpg" alt="Feature Icon" class="bg-transparent rounded-0">
											</div>
											<div class="fbox-content">
												<h3 class="nott ls0 text-larger">Sales Development</h3>
												<p>
                                                    Our sales representatives do the front-end work of tirelessly calling and engaging with potential partners. We focus our efforts on qualifying leads at the initial stages in the sales funnel.
                                                </p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col-lg-6 col-md-6 d-flex">
								<div class="card">
									<div class="card-body p-5">
										<div class="feature-box flex-column">
											<div class="mb-3">
												<img src="/assets/images/services/pagecards/sales2.jpg" alt="Feature Icon" class="bg-transparent rounded-0">
											</div>
											<div class="fbox-content">
												<h3 class="nott ls0 text-larger">Sales Outreach</h3>
												<p>
                                                    eFlex sales representatives handle customer interactions that generate added sales revenue. We do outgoing sales calls that provide product and service information and complete all follow up to close sales and drive resolution.
                                                </p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Contact/>
			</div>
	</section>
  </div>
</template>

<script>
// @ is an alias to /src
import Contact from '@/components/ContactUs.vue'

export default {
	name: 'Sales',
	data() {
		return {

		}
	},
	components: {
		Contact
	},
	computed: {
		
	},
	methods: {
		
	},
	mounted() {
		window.scrollTo(0, 0)
	}
}
</script>
